import React from "react"
import { RichText } from "prismic-reactjs"

import "./Products.scss"

function Products({ content }) {

  return (
    <div className="Product">
      <div className="Product-title">
        {RichText.asText(content.primary.title.richText)}
      </div>
      <ul>
        {content.items.map((x, i) => <li key={i}>{RichText.asText(x.product_name.richText)}</li>)}
      </ul>
    </div>
  )
}

export default Products
