import React from "react"
import { RichText } from "prismic-reactjs"

import "./Profil.scss"

function Profil({ content }) {

  return (
    <div className="Profil">
      <div className="container">
        <div className="Profil-sides">
          <div className="Profil-side Profil-side--visual">
            <div
              className="Profil-visual-img"
              style={{ backgroundImage: `url(${content.logo?.url})` }}
            ></div>
          </div>
          <div className="Profil-side Profil-side--text">
            <div className="edito">
              <span className="edito--title">
                {content?.name && RichText.render(content.name.richText)}
              </span>
                {content?.description && RichText.render(content.description.richText)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profil
