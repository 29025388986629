import React from "react"
import { injectIntl } from "gatsby-plugin-intl"

import Product from "@components/brand/products/Product"

import "./Products.scss"

function Products({ intl, content }) {

  return (
    <div className="Products">
      <div className="container">
        <div className="Product-list">
          {content.length ? (
            content.map((x, i) => <Product key={i} content={x} />)
          ) : (
            <p>{intl.formatMessage({ id: "no_products" })}</p>
          )}
        </div>
      </div>
    </div>
  )
}

export default injectIntl(Products)
