import React from "react"
import { graphql } from 'gatsby'
import { RichText } from "prismic-reactjs"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "@layouts/Layout"
import Metatag from "@components/metatag/Metatag"
import BannerPage from "@components/common/banner-page/BannerPage"
import Breadcrumb from "@components/common/breadcrumb/Breadcrumb" 
import Profil from "@components/brand/profil/Profil"
import Products from "@components/brand/products/Products"

function BrandPage({ intl, location, data }) {

  const content = data?.prismicBrandspage?.data
  const brand = data?.prismicBrand?.data

  if (!content || !brand) return null

  return (
    <Layout>
      <Metatag href={location.href} origin={location.origin} />
      <BannerPage
        texte={intl.formatMessage({ id: "brands" })}
        visual={content?.cover}
      />
      <Breadcrumb 
        path={location.pathname}
        pageName={RichText.asText(brand?.name.richText)}
      />
      <Profil content={brand} />
      <Products content={brand?.body} />
    </Layout>
  )
}

export const query = graphql`
  query brandpageQuery($uid: String, $gatsby_lang: String) {
    prismicBrand(uid: { eq: $uid }, lang: { eq: $gatsby_lang }) {
      uid
      id
      data {
        description {richText}
        name {richText}
        logo {
          alt
          url
        }
        body {
          ... on PrismicBrandDataBodyCategory {
            id
            slice_label
            slice_type
            primary {
              title {richText}
            }
            items {
              product_name {richText}
            }
          }
        }
      }
    }
    prismicBrandspage(lang: { eq: $gatsby_lang }) {
      data {
        cover {
          url
          alt
          thumbnails {
            mobile {
              url
            }
            tablet {
              url
            }
          }
        }
      }
    }
  }
`

export default injectIntl(BrandPage)
